import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Rate } from "../../../types";
import { daysOfWeek } from "../../../assets";
import { getRateAPI } from "../../../axios";
import { useIsMobile, useReport } from "../../../hooks";
import { ConnectOneTable, ConnectOneTableControls } from "../../../components";

import "./Rates.scss";

export const RateReport = () => {
  const isMobile = useIsMobile();
  const agencies = useSelector((state: any) => state.agencies.agenciesData);
  const classTypes = useSelector(
    (state: any) => state.classTypes.classTypesData
  );

  const classTypeMap = new Map(
    classTypes.map((classType: { _id: string; name: string }) => [
      classType._id,
      classType.name,
    ])
  );

  const {
    reportData,
    currentPageIndex,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    exportFileName,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    handleSorting,
  } = useReport({ isMobile, reportType: "rates" });

  const [filterValues, setFilterValues] = useState({
    agencyId: {
      type: "autocomplete",
      label: "agency",
      value: "",
      options: [] as any,
      placeholderText: "Select an agency",
    },
    roadName: {
      label: "Road Name",
      value: "",
    },
  });

  useEffect(() => {
    setFilterValues((prevFilterValues: any) => ({
      ...prevFilterValues,
      agencyId: {
        ...prevFilterValues.agencyId,
        options: [
          { label: "Select an agency", value: "", disabled: true },
          ...agencies,
        ],
      },
    }));
  }, [agencies]);

  const submitButtonEnabled = Object.values(filterValues).some(
    (filterValue: any) => !!filterValue.value
  );

  // Shape the nested data to display in table
  // For each schedule and charge we should have a row to display
  const shapeRatesData = (data: any) => {
    return data.reduce((acc: any[], rate: any) => {
      const { name, schedules } = rate;
      const { agencyId, roadName } = rate?.properties;
      const { name: agencyName, state } = agencies?.find(
        (agency: any) => agency._id === agencyId
      );

      // If distance based we shuld change location name to be Entry / Exit
      return acc.concat(
        schedules.reduce((innerAcc: any[], schedule: any) => {
          let startOfSegmentLocationName: any = null;
          if (schedule?.startOfSegmentLocationId) {
            startOfSegmentLocationName = data.find(
              (location: any) =>
                location._id === schedule?.startOfSegmentLocationId
            )?.name;
          }
          const { charges } = schedule;
          if (charges && charges.length > 0) {
            return innerAcc.concat(
              charges.map((charge: any) => ({
                ...schedule,
                ...charge,
                name: startOfSegmentLocationName
                  ? `Entry: ${startOfSegmentLocationName}\nExit: ${name} `
                  : name,
                agencyName,
                state,
                roadName: roadName ? roadName : "",
                startOfSegmentLocationName,
                dayOfWeekStart: daysOfWeek[schedule?.dayOfWeekStart - 1],
                dayOfWeekEnd: daysOfWeek[schedule?.dayOfWeekEnd - 1],
                vehicleClass: classTypeMap.get(schedule.vehicleClass),
              }))
            );
          } else {
            return innerAcc.concat({
              ...schedule,
              name: startOfSegmentLocationName
                ? `Entry ${startOfSegmentLocationName}\nExit ${name} `
                : name,
              agencyName,
              state,
              roadName: roadName ? roadName : "",
              startOfSegmentLocationName,
              dayOfWeekStart: daysOfWeek[schedule?.dayOfWeekStart - 1],
              dayOfWeekEnd: daysOfWeek[schedule?.dayOfWeekEnd - 1],
              vehicleClass: classTypeMap.get(schedule.vehicleClass),
            });
          }
        }, [])
      );
    }, []);
  };

  const getTableData = async () => {
    setIsLoading(true);
    setCurrentPageIndex(1);
    try {
      const res: any = await getRateAPI({
        agencyId: filterValues.agencyId.value,
        roadName: filterValues.roadName.value,
      });
      setReportData(shapeRatesData(res));
    } catch (err) {
      setReportData([] as Rate[]);
    }
    setIsLoading(false);
  };

  return (
    <div className="rates-report-container">
      <ConnectOneTableControls
        fullData={reportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={getTableData}
        submitButtonDisabled={!submitButtonEnabled}
        fileName={exportFileName("RATES_REPORT", filterValues)}
        exportData={exportData}
        reportTitle="Location Rates"
        reportDescription="This report contains the Toll rates by Plaza and Agency.  Use it to look up rates to inspect for accuracy or for comparison sake."
      />

      <ConnectOneTable
        data={dataToDisplay}
        headers={headers}
        caption="Location Rates"
        sortGridData={handleSorting}
        sortAscending={sortAscending}
        sortedValue={sortedValue}
      />
    </div>
  );
};
