import { useState } from "react";
import { Provider } from "react-redux";
import { Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { store } from "./store";
import { useIsMobile } from "./hooks";
import { resetIdleTimeout } from "./utils";
import { VerraLogoWithText } from "./assets";
import { ConnectOneNavBar } from "./components";
import { AdminRouterWrapper } from "./AdminRouterWrapper";
import {
  MapPage,
  TransactionReport,
  TollMatchingReport,
  RUCReport,
  VehicleTracesReport,
  RateReport,
  AdminControlAgencies,
  AdminControlVehicles,
  AdminControlServices,
  AdminControlLocations,
  VehicleStatusesReport,
  UserManagementSettings,
  VehicleActivityReport,
  AdminControlTollImport,
  AdminControlMessages,
  VehicleCommunicationsReport,
} from "./pages";

import "./App.scss";
import "@aws-amplify/ui-react/styles.css";

export const App = () => {
  const isMobile = useIsMobile();
  const [minimizedNav, setMinimizedNav] = useState(false);

  const components = {
    Header() {
      return (
        <div className="login-header">
          <VerraLogoWithText />
        </div>
      );
    },
  };

  return (
    <Authenticator components={components} hideSignUp={true}>
      {({ signOut, user }) => {
        if (!user) {
          window.removeEventListener("mousemove", resetIdleTimeout);
          window.removeEventListener("keydown", resetIdleTimeout);
          window.removeEventListener("touchstart", resetIdleTimeout);
        }

        const updatedSignOut = () => {
          // Remove event listeners when the component is unmounted
          window.removeEventListener("mousemove", resetIdleTimeout);
          window.removeEventListener("keydown", resetIdleTimeout);
          window.removeEventListener("touchstart", resetIdleTimeout);
          signOut();
        };

        const userType = user.attributes["custom:userType"];
        return (
          <Provider store={store}>
            <BrowserRouter>
              <ConnectOneNavBar
                user={user}
                signOut={updatedSignOut}
                minimizedNav={minimizedNav}
                setMinimizedNav={setMinimizedNav}
              />
              <div
                className={`${
                  minimizedNav
                    ? "minimized-desktop-nav"
                    : isMobile
                    ? "mobile-nav"
                    : "desktop-nav"
                }
              `}>
                <Routes>
                  {/* Map Page */}
                  <Route path="/" element={<MapPage user={user} />} />
                  {/* Report Pages */}
                  <Route
                    path="/report/toll-activity"
                    element={<TransactionReport />}
                  />
                  <Route
                    path="/report/vehicle-activity"
                    element={<VehicleActivityReport />}
                  />
                  <Route
                    path="/report/vehicle-messages"
                    element={<VehicleCommunicationsReport />}
                  />
                  <Route
                    path="/report/vehicle-status"
                    element={<VehicleStatusesReport />}
                  />
                  <Route
                    path="/report/vehicle-traces"
                    element={<VehicleTracesReport />}
                  />
                  <Route path="/report/agency-rates" element={<RateReport />} />
                  {/* Admin Pages */}
                  <Route element={<AdminRouterWrapper userType={userType} />}>
                    <Route
                      path="/admin/agencies"
                      element={<AdminControlAgencies user={user} />}
                    />
                  </Route>
                  <Route element={<AdminRouterWrapper userType={userType} />}>
                    <Route
                      path="/admin/locations"
                      element={<AdminControlLocations user={user} />}
                    />
                  </Route>
                  <Route element={<AdminRouterWrapper userType={userType} />}>
                    <Route
                      path="/admin/messages"
                      element={<AdminControlMessages user={user} />}
                    />
                  </Route>
                  <Route element={<AdminRouterWrapper userType={userType} />}>
                    <Route
                      path="/admin/services"
                      element={<AdminControlServices user={user} />}
                    />
                  </Route>
                  <Route element={<AdminRouterWrapper userType={userType} />}>
                    <Route path="/admin/report/ruc" element={<RUCReport />} />
                  </Route>
                  <Route element={<AdminRouterWrapper userType={userType} />}>
                    <Route
                      path="/admin/report/tca-matching"
                      element={<TollMatchingReport />}
                    />
                  </Route>
                  <Route element={<AdminRouterWrapper userType={userType} />}>
                    <Route
                      path="/admin/tollSmartImport"
                      element={<AdminControlTollImport user={user} />}
                    />
                  </Route>
                  <Route element={<AdminRouterWrapper userType={userType} />}>
                    <Route
                      path="/admin/vehicles"
                      element={<AdminControlVehicles user={user} />}
                    />
                  </Route>
                  {/* Settings Pages */}
                  <Route element={<AdminRouterWrapper userType={userType} />}>
                    <Route
                      path="/settings/users"
                      element={<UserManagementSettings user={user} />}
                    />
                  </Route>
                </Routes>
              </div>
            </BrowserRouter>
          </Provider>
        );
      }}
    </Authenticator>
  );
};
