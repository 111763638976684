import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { useAdmin } from "../../../hooks";
import { putService } from "../../../redux";
import { ServicesEditForm } from "./ServicesEditForm";
import { ServicesHeaders, servicesEditState } from "../../../assets";
import {
  ConnectOneClickableTable,
  ConnectOneLoader,
} from "../../../components";
import { isMobile } from "react-device-detect";

export const AdminControlServices = ({ user }: { user?: any }) => {
  const {
    initialLoad,
    servicesData: services,
    serviceStatusData: statusTypes,
  } = useSelector((state: any) => state.services);
  const locationTypes = useSelector(
    (state: any) => state.locationHierarchy.locationHierarchyData
  );

  useEffect(() => {
    if (!isEmpty(locationTypes)) {
      let locationTypesOptions = [];
      for (let [locationType] of locationTypes) {
        locationTypesOptions.push({
          label: locationType,
          value: locationType,
          name: locationType,
        });
      }
      servicesEditState.LocationTypes.options = locationTypesOptions;
    }
    if (statusTypes?.length) servicesEditState.status.options = statusTypes;
  }, [locationTypes, statusTypes]);

  const {
    isEditing,
    isLoading,
    responseData,
    editData,
    isCreating,
    sortedValue,
    sortAscending,
    handleAdd,
    setIsViewing,
    handleSorting,
    handleGoBack,
    handleEditInputChange,
    setResponseData,
    setIsSearching,
    handleRowSelection,
    handleEditFormSubmit,
    handleCheckboxChange,
  } = useAdmin({
    editState: servicesEditState,
    handleSubmit: putService,
    user: user,
  });

  useEffect(() => {
    setIsSearching(false);
    setResponseData(services);
    setIsViewing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsSearching, setResponseData, setIsViewing, services]);

  return (
    <>
      {initialLoad && <ConnectOneLoader />}

      <div>
        <ConnectOneClickableTable
          data={responseData}
          label={"Services"}
          headers={ServicesHeaders}
          sortGridData={handleSorting}
          handleRowSelection={handleRowSelection}
          actionButtonLabel={"Add New Service"}
          handleActionButton={handleAdd}
          tableBodyHeight={isMobile ? "240px" : undefined}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
        />
      </div>

      {isEditing && (
        <ServicesEditForm
          data={editData}
          label={"Service Type"}
          handleChange={handleEditInputChange}
          handleGoBack={handleGoBack}
          handleSubmit={handleEditFormSubmit}
          handleCheckbox={handleCheckboxChange}
          isLoading={isLoading}
          isCreating={isCreating}
        />
      )}
    </>
  );
};
