// Route values for our NavBar to display
// adminOnly value is optional but when true any user not userType "admin" will not see on NavBar

import { EditIcon, MapIcon, TableIcon, SettingsIcon } from "../icons";

export const routes = [
  {
    name: "Map",
    path: "/",
    icon: MapIcon,
  },
  {
    name: "Report",
    icon: TableIcon,
    basePath: "/report",
    children: [
      {
        name: "Agency Rates",
        path: "/report/agency-rates",
      },
      {
        name: "Toll Activity",
        path: "/report/toll-activity",
      },
      {
        name: "Vehicle Activity",
        path: "/report/vehicle-activity",
      },
      {
        name: "Vehicle Messages",
        path: "/report/vehicle-messages",
      },
      {
        name: "Vehicle Status",
        path: "/report/vehicle-status",
      },
      {
        name: "Vehicle Traces",
        path: "/report/vehicle-traces",
      },
    ],
  },
  {
    name: "Admin",
    icon: EditIcon,
    basePath: "/admin",
    adminOnly: true,
    children: [
      {
        name: "Agencies",
        path: "/admin/agencies",
        adminOnly: true,
      },
      {
        name: "Locations",
        path: "/admin/locations",
        adminOnly: true,
      },
      {
        name: "Messages",
        path: "/admin/messages",
        adminOnly: true,
      },
      {
        name: "RUC",
        path: "/admin/report/ruc",
        adminOnly: true,
      },
      {
        name: "Services",
        path: "/admin/services",
        adminOnly: true,
      },
      {
        name: "TCA Matching",
        path: "/admin/report/tca-matching",
        adminOnly: true,
      },
      {
        name: "TollSmart Import",
        path: "/admin/tollSmartImport",
        adminOnly: true,
      },
      {
        name: "Vehicles",
        path: "/admin/vehicles",
        adminOnly: true,
      },
    ],
  },
  {
    name: "Settings",
    icon: SettingsIcon,
    basePath: "/settings",
    adminOnly: true,
    children: [
      {
        name: "Manage Users",
        path: "/settings/users",
        adminOnly: true,
      },
    ],
  },
];
