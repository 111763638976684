import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@aws-amplify/ui-react";

import { AppDispatch } from "../../../store";
import { findAllEntryPoints, shapeCSVData } from "../../../utils";
import { LocationsEditForm } from "./LocationsEditForm";
import { useAdmin, useGeofence, useIsMobile } from "../../../hooks";
import {
  GeofenceHeaders,
  LocationHeaders,
  locationsFilterState,
} from "../../../assets";
import {
  handleGeofenceSort,
  insertAgencyNames,
  handleGeofenceFilter,
  insertLocationStatusNames,
} from "../../../redux";
import {
  ConnectOneClickableTable,
  ConnectOneLoader,
  ConnectOneSearchForm,
} from "../../../components";
import { BulkUpload } from "./BulkUpload";

import "./Locations.scss";

export const AdminControlLocations = ({ user }: { user?: any }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [exportData, setExportData] = useState({
    cellHeaders: null,
    cellData: null,
  });
  const [templateData, setTemplateData] = useState({
    cellHeaders: null,
    cellData: null,
  });

  const {
    initialLoad,
    geofencesData,
    rawGeofenceData,
    isLoading: geofencesLoading,
  } = useSelector((state: any) => state.geofences);
  const { agenciesData, isLoading: agenciesLoading } = useSelector(
    (state: any) => state.agencies
  );
  const { locationStatusesData, isLoading: locationStatusesLoading } =
    useSelector((state: any) => state.locationStatuses);
  const locationHierarchy = useSelector(
    (state: any) => state.locationHierarchy.locationHierarchyData
  );

  if (agenciesData.length) {
    locationsFilterState.agencyId.options = agenciesData;
  }
  if (locationStatusesData.length) {
    locationsFilterState.status.options = locationStatusesData;
  }
  if (locationHierarchy?.get) {
    const locationTypes: { label: string; value: string }[] = [];

    locationHierarchy.forEach((locationType: any) => {
      locationTypes.push({
        label: locationType.locationType,
        value: locationType.locationType,
      });
    });

    locationsFilterState.locationType.options = locationTypes;
  }

  // Any dropdown type should be given an Any option
  Object.entries(locationsFilterState).forEach(
    ([key, value]: [key: string, value: any]) => {
      if (value.type === "dropdown") {
        // Do not add a second Any option
        if (value?.options[0] && value.options[0].value !== "") {
          value.options = [{ label: "Any", value: "" }, ...value.options];
        }
      }
    }
  );

  const {
    isSearching,
    isLoading,
    searchData,
    displayingFilteredData,
    sortedValue,
    sortAscending,
    handleFilterInputChange,
    setIsSearching,
    handleFilterCheckboxChange,
    handleResetSearch,
    setSortedValue,
    setSortAscending,
  } = useAdmin({
    filterState: locationsFilterState,
    user,
  });

  const {
    modalData,
    displayGeofenceModal,
    handleRemoveScheduleOrRate,
    handleGeofenceSelection,
    handleGeofenceModalClose,
    handleGeofenceSave,
    handleGeofencePropertyChange,
    handleScheduleOrRateChange,
    handleInsertScheduleOrRate,
    handleScheduleSave,
    handleUndoScheduleChanges,
    handleRemoveExclusiveDate,
  } = useGeofence({
    rawGeofenceData: rawGeofenceData,
    isOnAdminPage: true,
  });

  useEffect(() => {
    if (geofencesData?.features) {
      const { cellHeaders, cellData } = shapeCSVData(
        LocationHeaders,
        geofencesData.features
      );

      setExportData({ cellHeaders, cellData });
    }
  }, [geofencesData.features]);

  useEffect(() => {
    if (geofencesData?.features) {
      const { cellHeaders, cellData } = shapeCSVData(
        GeofenceHeaders,
        geofencesData.features
      );

      setTemplateData({ cellHeaders, cellData });
    }
  }, [geofencesData.features]);

  useEffect(() => {
    if (!agenciesLoading && !geofencesLoading) {
      dispatch(insertAgencyNames({ agencies: agenciesData }));
    }
  }, [agenciesData, agenciesLoading, dispatch, geofencesLoading]);

  useEffect(() => {
    if (!locationStatusesLoading && !geofencesLoading) {
      dispatch(
        insertLocationStatusNames({ locationStatuses: locationStatusesData })
      );
    }
  }, [
    dispatch,
    geofencesLoading,
    locationStatusesData,
    locationStatusesLoading,
  ]);

  const handleViewOnMap = (locationId: string) => {
    navigate({
      pathname: "/",
      search: `?locationId=${locationId}`,
    });
  };

  const handleSortGrid = (header: string) => {
    if (sortedValue === header && !sortAscending) {
      setSortAscending(true);
      dispatch(
        handleGeofenceSort({ sortedValue: header, sortAscending: true })
      );
    } else {
      setSortedValue(header);
      setSortAscending(false);
      dispatch(
        handleGeofenceSort({ sortedValue: header, sortAscending: false })
      );
    }
  };

  const handleFilterLocations = ({
    resetFilters = false,
  }: {
    resetFilters?: boolean;
  }) => {
    const filterParams: any = {};

    if (!resetFilters) {
      Object.entries(searchData).forEach(
        ([key, value]: [key: string, value: any]) => {
          if (value.value) {
            filterParams[key] = value.value;
          }
        }
      );
    }

    dispatch(handleGeofenceFilter(filterParams));
    setIsSearching(false);
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <div
      className={`${
        isMobile ? "mobile-locations-admin-container" : null
      } locations-admin-container`}
    >
      {initialLoad && <ConnectOneLoader />}

      <div>
        <ConnectOneClickableTable
          data={geofencesData.features}
          label={"Locations"}
          headers={LocationHeaders}
          exportData={exportData}
          sortGridData={handleSortGrid}
          resetButtonLabel={"Clear Search"}
          handleRowSelection={(row: any) =>
            handleGeofenceSelection({ features: [row] })
          }
          handleSearch={() => setIsSearching(true)}
          handleReset={
            displayingFilteredData
              ? () => {
                  handleResetSearch();
                  handleFilterLocations({ resetFilters: true });
                }
              : undefined
          }
          sortedValue={sortedValue}
          sortAscending={sortAscending}
        />
      </div>

      {isSearching && (
        <>
          <span className="co-modal-backdrop" />
          <ConnectOneSearchForm
            label={"Locations"}
            searchData={searchData}
            handleChange={handleFilterInputChange}
            handleCheckbox={handleFilterCheckboxChange}
            handleSearch={handleFilterLocations}
            isLoading={isLoading}
            handleClose={() => setIsSearching(false)}
          />
        </>
      )}
      <div className="button-container-locations">
        <div className="add-button">
          <Button className="locations-button" onClick={handleShow}>
            Upload Bulk Geofences
          </Button>
        </div>
      </div>
      <BulkUpload
        exportHeaders={templateData.cellHeaders}
        show={show}
        setShow={setShow}
      />

      {displayGeofenceModal && (
        <LocationsEditForm
          geofence={
            modalData?.properties?.locationSubType === "DISTANCEEXIT"
              ? {
                  ...modalData,
                  entryPoints: findAllEntryPoints({
                    rawGeofenceData,
                    selectedGeofence: modalData,
                  }),
                }
              : modalData
          }
          isLoading={geofencesLoading}
          handleChange={handleGeofencePropertyChange}
          handleGoBack={handleGeofenceModalClose}
          handleSubmit={async () => {
            await handleGeofenceSave({});
            handleFilterLocations({});
          }}
          handleViewOnMap={handleViewOnMap}
          handleInsertScheduleOrRate={handleInsertScheduleOrRate}
          handleScheduleOrRateChange={handleScheduleOrRateChange}
          handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
          handleScheduleSave={handleScheduleSave}
          handleRemoveExclusiveDate={handleRemoveExclusiveDate}
          handleUndoScheduleChanges={handleUndoScheduleChanges}
        />
      )}
    </div>
  );
};
