import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import { getTollImportMetadata } from "../../../axios";
import {
  ConnectOneClickableTable,
  ConnectOneOverlay,
} from "../../../components";
import {
  downloadFromS3,
  invokeLambdaFunction,
  sortGridData,
} from "../../../utils";
import {
  tollImportMetadataHeaders,
  tollImportGroupedHeaders,
} from "../../../assets";

import "./TollImport.scss";

export const AdminControlTollImport = ({ user }: { user?: any }) => {
  const [tollImportMetadata, setTollImportMetadata] = useState<any>([]);
  const [groupedTollImportMetadata, setGroupedTollImportMetadata] =
    useState<any>([]);
  const [lastInvokeDateTime, setLastInvokeDateTime] =
    useState<string>(undefined);

  const [sortAscending, setSortAscending] = useState(true);
  const [sortedValue, setSortedValue] = useState("");

  const [isViewing, setIsViewing] = useState(true);
  const [hasSelected, setHasSelected] = useState(false);
  const [selectedData, setSelectedData] = useState<any>({});

  const getTableData = async () => {
    let tollImportMetadata = await getTollImportMetadata();
    setTollImportMetadata(tollImportMetadata || []);
    setGroupedTollImportMetadata(handleGroupData(tollImportMetadata || {}));
  };

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tollImportMetadata.length > 0) {
      // Find the latest invokeDateTime and format for prompt
      const lastInvokeDateTime = new Date(
        Math.max(
          ...tollImportMetadata.map(
            (data: any) => new Date(data.invokeDateTime)
          )
        )
      ).toLocaleDateString();
      setLastInvokeDateTime(lastInvokeDateTime);
    }
  }, [tollImportMetadata]);

  const handleExecuteImport = async () => {
    if (
      window.confirm(
        `You are about to execute a TollSmart import${
          lastInvokeDateTime ? `, last ran on ${lastInvokeDateTime}.` : "."
        }\nIt will take approximately 10 minutes, please refresh data to see updated.\nDo you want to continue?`
      )
    ) {
      await invokeLambdaFunction({
        functionName: `TollImportLambda-${process.env.REACT_APP_USER_BRANCH}`,
        username: user.username,
      });
    }
  };

  const handleGroupData = (data: any) => {
    const groupedData = data.reduce((acc: any, curr: any) => {
      const { invokeId, invokeDateTime, invokedUser } = curr;

      if (!acc[invokeId]) {
        acc[invokeId] = {
          invokeId,
          invokeDateTime,
          invokedUser,
          countFilesRan: 0,
          totalSuccess: 0,
          totalFailed: 0,
          totalNewlyCreated: 0,
        };
      }
      acc[invokeId].countFilesRan += 1;
      acc[invokeId].totalSuccess += curr.successCount;
      acc[invokeId].totalFailed += curr.failureCount;
      acc[invokeId].totalNewlyCreated += curr.newlyCreatedCount;

      // Find the earliest invokeDateTime
      if (new Date(acc[invokeId].invokeDateTime) > new Date(invokeDateTime)) {
        acc[invokeId].invokeDateTime = invokeDateTime;
      }

      return acc;
    }, {});

    const sortedGroupedData = Object.entries(groupedData)
      .map(([invokeId, value]) => value)
      .sort(
        (a: any, b: any) =>
          // @ts-ignore
          new Date(b.invokeDateTime) - new Date(a.invokeDateTime)
      );
    return sortedGroupedData;
  };

  const handleSorting = (header: string) => {
    if (sortedValue === header && !sortAscending) {
      const sortedReportData = sortGridData({
        unsortedData: hasSelected ? selectedData : groupedTollImportMetadata,
        sortValue: header,
        ascending: false,
      });
      setSortAscending(true);
      if (hasSelected) {
        setSelectedData(sortedReportData);
      } else {
        setGroupedTollImportMetadata(sortedReportData);
      }
      return;
    }
    const sortedReportData = sortGridData({
      unsortedData: hasSelected ? selectedData : groupedTollImportMetadata,
      sortValue: header,
      ascending: true,
    });
    setSortedValue(header);
    setSortAscending(false);
    if (hasSelected) {
      setSelectedData(sortedReportData);
    } else {
      setGroupedTollImportMetadata(sortedReportData);
    }
    return;
  };

  const handleRowSelection = (row: any) => {
    const tollImportData = tollImportMetadata.filter(
      (data: any) => data.invokeId === row.invokeId
    );
    setSelectedData(tollImportData);
    setIsViewing(true);
    setHasSelected(true);
  };

  const handleDownloadDetailedReport = async (row: any) => {
    const url = new URL(row.csvDownloadURL);
    const { host, pathname } = url;
    const bucketName = host.split(".")[0];
    const fileName = pathname.substring(1);
    await downloadFromS3({
      fileName: fileName,
      bucketName: bucketName,
    });
  };

  const handleCloseModal = () => {
    setSelectedData({});
    setHasSelected(false);
  };

  return (
    <div className="toll-import-page">
      {isViewing && (
        <ConnectOneClickableTable
          data={groupedTollImportMetadata}
          label={"TollSmart Import"}
          headers={tollImportGroupedHeaders}
          sortGridData={handleSorting}
          handleRowSelection={handleRowSelection}
          actionButtonLabel={"Execute Import"}
          handleActionButton={handleExecuteImport}
          handleReset={getTableData}
          resetButtonLabel={"Refresh Data"}
          tableBodyHeight={isMobile ? "240px" : undefined}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
        />
      )}

      {hasSelected && (
        <>
          <ConnectOneOverlay />
          <div className="toll-import-modal">
            <span
              className="close"
              aria-hidden="true"
              onClick={handleCloseModal}>
              &times;
            </span>
            <span className="modal-helper">
              <p>Click a row to download the row's detailed report.</p>
            </span>
            <ConnectOneClickableTable
              data={selectedData}
              label={`${new Date(
                selectedData[0]?.invokeDateTime
              ).toLocaleDateString()} Processed Files`}
              headers={tollImportMetadataHeaders}
              sortGridData={handleSorting}
              handleRowSelection={handleDownloadDetailedReport}
              tableBodyHeight={isMobile ? "240px" : "190px"}
              sortedValue={sortedValue}
              sortAscending={sortAscending}
            />
          </div>
        </>
      )}
    </div>
  );
};
