export const GlobeIcon = ({ strokeColor }: { strokeColor: string }) => {
  return (
    <>
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 420 420"
        id="Global_Icon"
        data-name="Global Icon"
        data-testid="globe-icon"
        stroke={strokeColor}
        fill="none"
      >
        <path strokeWidth="24" d="M209,15a195,195 0 1,0 2,0z" />
        <path
          strokeWidth="18"
          d="m210,15v390m195-195H15M59,90a260,260 0 0,0 302,0 m0,240 a260,260 0 0,0-302,0M195,20a250,250 0 0,0 0,382 m30,0 a250,250 0 0,0 0-382"
        />
      </svg>
    </>
  );
};
