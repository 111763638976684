import { useEffect, useState } from "react";
import { addDays, differenceInDays, format } from "date-fns";

import { VehicleTraces } from "../../../types";
import { shapeTraceData } from "../../../utils";
import { useIsMobile, useReport } from "../../../hooks";
import { getVehicleTracesAPI, getVehicleTracesCountAPI } from "../../../axios";
import {
  ConnectOneLoader,
  ConnectOneTable,
  ConnectOneTableControls,
} from "../../../components";

export const VehicleTracesReport = () => {
  const isMobile = useIsMobile();
  const chunkSize = 1000;

  const {
    reportData,
    currentPageIndex,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    exportFileName,
    setTotalPages,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    handleSorting,
  } = useReport({
    isMobile,
    reportType: "vehicleTraces",
    dataPerPage: chunkSize,
  });

  // Keep track of which pages have been loaded
  const [pagesLoaded, setPagesLoaded] = useState<any>({});

  const [filterValues, setFilterValues] = useState({
    vin: {
      type: "autocomplete",
      label: "Vin",
      value: "",
      placeholderText: "Select a VIN",
    },
    dateFrom: {
      type: "datetime-local",
      label: "Date From",
      value: `${format(addDays(new Date(), -1), "yyyy-MM-dd")}T00:00`,
      min: `${format(addDays(new Date(), -31), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Date To",
      value: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
      max: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
    },
  });

  const dateFrom = new Date(filterValues.dateFrom.value);
  const dateTo = new Date(filterValues.dateTo.value);

  // Keep the dateFrom value within min 31 days before dateTo
  useEffect(() => {
    if (differenceInDays(dateTo, dateFrom) <= 31) {
      setFilterValues((prev) => ({
        ...prev,
        dateFrom: {
          ...prev.dateFrom,
          min: format(addDays(dateTo, -31), "yyyy-MM-dd'T'HH:mm"),
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues.dateTo]);

  const submitButtonEnabled =
    differenceInDays(dateTo, dateFrom) <= 31 &&
    Object.values(filterValues).some((filterValue: any) => !!filterValue.value);

  const pagesLoading = Object.values(pagesLoaded).some(
    (pageLoaded: any) => !pageLoaded
  );

  // For vehicle traces we need to get the count of all traces in the date range
  // Then show the first chunkSize results
  // If there are more than chunkSize results, we need to show the pagination controls
  // and pull the rest of the data behind the scenes
  const getTableData = async () => {
    try {
      // If the the date range is greater than a week we want to give the user a warning that it may take some time to load
      if (differenceInDays(dateTo, dateFrom) > 7) {
        if (
          !window.confirm(
            "This date range is greater than 7 days. It may take some time to load. Are you sure you want to continue?"
          )
        ) {
          return;
        }
      }

      let totalPagesForQuery = 0;
      setReportData([] as VehicleTraces[]);
      setCurrentPageIndex(1);

      setIsLoading(true);
      await getVehicleTracesCountAPI({
        vin: filterValues.vin.value,
        dateFrom: new Date(filterValues.dateFrom.value).valueOf(),
        dateTo: new Date(filterValues.dateTo.value).valueOf(),
      }).then((res) => {
        totalPagesForQuery = Math.ceil(res / chunkSize);
        setTotalPages(totalPagesForQuery);
        const pageLoad: any = {};
        for (let i = 1; i <= totalPagesForQuery; i++) {
          pageLoad[i] = false;
        }
        setPagesLoaded(pageLoad);
      });

      const res: any = await getVehicleTracesAPI(
        {
          vin: filterValues.vin.value,
          dateFrom: new Date(filterValues.dateFrom.value).valueOf(),
          dateTo: new Date(filterValues.dateTo.value).valueOf(),
        },
        1
      );
      const data = shapeTraceData(res);
      setReportData(data);
      setIsLoading(false);
      setPagesLoaded((prev: any) => ({ ...prev, 1: true }));

      if (totalPagesForQuery > 1) {
        for (let i = 2; i <= totalPagesForQuery; i++) {
          await getVehicleTracesAPI(
            {
              vin: filterValues.vin.value,
              dateFrom: new Date(filterValues.dateFrom.value).valueOf(),
              dateTo: new Date(filterValues.dateTo.value).valueOf(),
            },
            i
          ).then((res) => {
            setPagesLoaded((prev: any) => ({ ...prev, [i]: true }));
            const data = shapeTraceData(res);
            setReportData((prevData: any) => [...prevData, ...data]);
          });
        }
      }
    } catch (err) {
      setReportData([] as VehicleTraces[]);
    }
  };

  return (
    <>
      {currentPageIndex && !pagesLoaded[currentPageIndex] && (
        <span className="data-table-loader">
          <ConnectOneLoader />
        </span>
      )}

      <ConnectOneTableControls
        fullData={reportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={getTableData}
        submitButtonDisabled={!submitButtonEnabled}
        buttonToolTipMessage={
          differenceInDays(dateTo, dateFrom) > 31
            ? "Date range must be 31 days or less"
            : ""
        }
        fileName={exportFileName(`VEHICLE_TRACES_REPORT`, filterValues)}
        exportData={exportData}
        exportDataLoading={pagesLoading}
        reportTitle="Vehicle Traces Report"
      />

      <ConnectOneTable
        data={dataToDisplay}
        headers={headers}
        caption="Vehicle Traces"
        sortGridData={handleSorting}
        sortAscending={sortAscending}
        sortedValue={sortedValue}
      />
    </>
  );
};
