import { useEffect, useState } from "react";
import { format } from "date-fns";

import { getUserTypesAPI } from "../axios";
import {
  getUsersFromUserPool,
  updateUsersAttributes,
  addNewUser,
  sortGridData,
  changeUserStatus,
  trimStringEndWhiteSpace,
} from "../utils";

export type SelectedUser = {
  username: string;
  email: string;
  phone: string;
  role: string;
  enabled: boolean;
  createDate: string;
  lastModifiedDate: string;
};

export const useUserManagement = () => {
  // User State
  const [users, setUsers] = useState<any[]>([]);
  const [userTypes, setUserTypes] = useState([]);
  const [selectedUser, setSelectedUser] = useState<SelectedUser>(
    {} as SelectedUser,
  );

  // Sorting State
  const [sortedValue, setSortedValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);

  // Modal State
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    getUserTypesAPI().then((data) => {
      setUserTypes(
        data.map((type: any) => ({
          label: type.name,
          value: type.value,
        })),
      );
    });
  }, []);

  useEffect(() => {
    if (!userTypes.length) return;
    const shapeUserData = (data: any) => {
      return data.map((user: any) => {
        const role =
          userTypes.find(
            (type: any) =>
              type.value ===
              user.Attributes.find(
                (attr: any) => attr.Name === "custom:userType",
              )?.Value,
          )?.value || { label: "Basic", value: "basic" }?.value;

        return {
          username: user.Username,
          email: user.Attributes.find((attr: any) => attr.Name === "email")
            ?.Value,
          phone:
            user.Attributes.find(
              (attr: any) => attr.Name === "phone_number",
            )?.Value?.slice(1) || "N/A",
          role: role,
          enabled: user.Enabled,
          createDate: format(
            new Date(user.UserCreateDate),
            "yyyy-MM-dd HH:mm:ss",
          ),
          lastModifiedDate: format(
            new Date(user.UserLastModifiedDate),
            "yyyy-MM-dd HH:mm:ss",
          ),
        };
      });
    };

    getUsersFromUserPool().then((data) => {
      setUsers(shapeUserData(data.Users));
    });
  }, [userTypes]);

  // Sorting Logic
  const handleSorting = (header: string) => {
    if (sortedValue === header && !sortAscending) {
      const sortedResponseData = sortGridData({
        unsortedData: users,
        sortValue: header,
        ascending: false,
      });
      setSortAscending(true);
      setUsers(sortedResponseData);
      return;
    }
    const sortedResponseData = sortGridData({
      unsortedData: users,
      sortValue: header,
      ascending: true,
    });
    setSortedValue(header);
    setSortAscending(false);
    setUsers(sortedResponseData);
    return;
  };

  // User Management Logic
  const handleRowSelection = (row: any) => {
    setIsEditing(true);
    setSelectedUser({ ...row, email: trimStringEndWhiteSpace(row.email) });
  };

  const handleAddUserSelection = () => {
    setSelectedUser({} as SelectedUser); // Clear selected user properties
    setIsEditing(true);
    setIsCreating(true);
  };

  const handleAddUser = async () => {
    const response = await addNewUser(selectedUser);
    if (response) {
      const user = response.User;
      const role =
        userTypes.find(
          (type: any) =>
            type.value ===
            user.Attributes.find((attr: any) => attr.Name === "custom:userType")
              ?.Value,
        )?.value || { label: "Basic", value: "basic" }?.value;

      const newUser = {
        username: user.Username,
        email: user.Attributes.find((attr: any) => attr.Name === "email")
          ?.Value,
        phone:
          user.Attributes.find(
            (attr: any) => attr.Name === "phone_number",
          )?.Value?.slice(1) || "N/A",
        role: role,
        enabled: user.Enabled,
        createDate: format(
          new Date(user.UserCreateDate),
          "yyyy-MM-dd HH:mm:ss",
        ),
        lastModifiedDate: format(
          new Date(user.UserLastModifiedDate),
          "yyyy-MM-dd HH:mm:ss",
        ),
      };
      setUsers([...users, newUser]);
      handleModalClose();
    }
  };

  const handleUserPropertyChange = (e: any) => {
    const { name, value } = e.target;

    setSelectedUser({ ...selectedUser, [name]: value });
  };

  const handleUpdateUser = async () => {
    const response = await updateUsersAttributes(selectedUser);
    if (response) {
      const updatedUsers = users.map((user) => {
        if (user.username === selectedUser.username) {
          return {
            ...selectedUser,
            lastModifiedDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
            phone:
              selectedUser.phone === "N/A"
                ? "N/A"
                : selectedUser.phone.slice(1),
          };
        }
        return user;
      });
      setUsers(updatedUsers);
      handleModalClose();
    }
  };

  const handleUserStatusChange = async () => {
    if (
      window.confirm(
        `Are you sure you want to ${selectedUser.enabled ? "disable" : "enable"} access for ${selectedUser.username}?`,
      )
    ) {
      await changeUserStatus(selectedUser.username, !selectedUser.enabled);
      const updatedUsers = users.map((user) => {
        if (user.username === selectedUser.username) {
          const updatedUser = {
            ...selectedUser,
            enabled: !selectedUser.enabled,
            lastModifiedDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          };
          setSelectedUser(updatedUser);
          return updatedUser;
        }
        return user;
      });
      setUsers(updatedUsers);
    }
  };

  // Clear state and close modal
  const handleModalClose = () => {
    setIsEditing(false);
    setIsCreating(false);
    setSelectedUser({} as SelectedUser);
  };

  return {
    users,
    userTypes,
    sortedValue,
    sortAscending,
    selectedUser,
    isCreating,
    isEditing,
    handleSorting,
    handleAddUser,
    handleAddUserSelection,
    handleUserPropertyChange,
    handleRowSelection,
    handleUpdateUser,
    handleModalClose,
    handleUserStatusChange,
  };
};
