// Custom hook created to handle related functionality between all the report pages
import { useEffect, useMemo, useState } from "react";

import {
  getHeaderNames,
  convertToUserTimezone,
  shapeCSVData,
  getCSVFileName,
  sortGridData,
  getFriendlyTimezoneName,
} from "../utils";

export const useReport = ({
  isMobile,
  reportType,
  dataPerPage = isMobile ? 6 : 12,
}: {
  isMobile: boolean;
  reportType: string;
  dataPerPage?: number;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([] as any);
  const [currentPageIndex, setCurrentPageIndex] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  const [sortedValue, setSortedValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);

  useEffect(() => {
    if (reportData.length && reportType !== "vehicleTraces") {
      setTotalPages(Math.ceil(reportData.length / dataPerPage));
    }
  }, [reportData, dataPerPage, reportType]);

  const dataToDisplay = useMemo(() => {
    return reportData.slice(
      currentPageIndex * dataPerPage - dataPerPage, // Get first index of new page
      currentPageIndex * dataPerPage // Get last index of new page
    );
  }, [currentPageIndex, dataPerPage, reportData]);

  const headers = getHeaderNames(reportType);

  const exportData = useMemo(() => {
    return shapeCSVData(headers, reportData);
  }, [headers, reportData]);

  const exportFileName = (reportType: string, filterValues: any) => {
    return getCSVFileName(reportType, filterValues);
  };

  const userFriendlyDataInsert = ({
    data,
    localDate,
    utcDate,
    timeZone,
  }: {
    data: any;
    localDate: string;
    utcDate: string;
    timeZone: string;
  }) => {
    return data.map((reportItem: any) => {
      if (reportItem[utcDate]) {
        return {
          ...reportItem,
          [localDate]: convertToUserTimezone(
            reportItem[utcDate],
            reportItem[timeZone]
          ),
          timeZoneName: getFriendlyTimezoneName(
            reportItem[timeZone],
            reportItem[utcDate]
          ),
        };
      }

      return reportItem;
    });
  };

  const insertGeofenceName = ({
    data,
    geofences,
  }: {
    data: any;
    geofences: any;
  }) => {
    return data.map((reportItem: any) => {
      if (reportItem["geofenceId"]) {
        return {
          ...reportItem,
          geofenceName: geofences.features.find(
            (geofence: any) => geofence.id === reportItem["geofenceId"]
          )?.name,
        };
      }

      return reportItem;
    });
  };

  const handleSorting = (header: string) => {
    if (sortedValue === header && !sortAscending) {
      const sortedReportData = sortGridData({
        unsortedData: reportData,
        sortValue: header,
        ascending: false,
      });
      setSortAscending(true);
      setReportData(sortedReportData);
      return;
    }
    const sortedReportData = sortGridData({
      unsortedData: reportData,
      sortValue: header,
      ascending: true,
    });
    setSortedValue(header);
    setSortAscending(false);
    setReportData(sortedReportData);
    return;
  };

  return {
    reportData,
    currentPageIndex,
    dataPerPage,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    setTotalPages,
    exportFileName,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    userFriendlyDataInsert,
    insertGeofenceName,
    handleSorting,
  };
};
