import { MessageEditForm } from "./MessageEditForm";
import { useMessageManagement } from "../../../hooks";
import {
  ConnectOneClickableTable,
  ConnectOneLoader,
} from "../../../components";

import "./Messages.scss";

export const AdminControlMessages = () => {
  const {
    isLoading,
    initialLoad,
    isViewing,
    isEditing,
    responseData,
    editData,
    messageTableHeaders,
    sortAscending,
    sortedValue,
    autoCompleteValue,
    autoCompleteOptions,
    messageTypes,
    handleSorting,
    handleRowSelection,
    handleGoBack,
    handleChange,
    setAutoCompleteValue,
    onAutoCompleteSelect,
    handleSubmit,
  } = useMessageManagement();

  return (
    <div className="messages-page-container">
      {initialLoad && <ConnectOneLoader />}

      {isViewing && (
        <ConnectOneClickableTable
          data={responseData}
          label={"In Vehicle Messages"}
          headers={messageTableHeaders}
          sortGridData={handleSorting}
          exportData={[]}
          handleRowSelection={handleRowSelection}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
          autoCompleteValue={autoCompleteValue}
          setAutoCompleteValue={setAutoCompleteValue}
          autoCompleteOptions={autoCompleteOptions}
          onAutoCompleteSelect={onAutoCompleteSelect}
        />
      )}

      {isEditing && (
        <>
          <span className="co-modal-backdrop" />
          <MessageEditForm
            data={editData}
            messageTypes={messageTypes}
            handleChange={handleChange}
            handleGoBack={handleGoBack}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};
