import { useEffect, useState } from "react";
import { Autocomplete } from "@aws-amplify/ui-react";
import { getVehicleInformationAPI, getAgencyListAPI } from "../../../axios";

import "./Autocomplete.scss";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ConnectOneAutocomplete = ({
  label,
  required,
  placeholderText,
  handleChange,
  options,
  handleSearch,
  filterValues,
  vinByLookup,
  setVinByLookup,
  cleanField,
  setCleanField,
}: {
  label: string;
  required?: boolean;
  placeholderText?: string;
  handleChange: any;
  options?: any;
  handleSearch?: any;
  filterValues?: any;
  vinByLookup?: boolean;
  setVinByLookup?: any;
  cleanField?: boolean;
  setCleanField?: any;
}) => {
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [autocompletePlate, setAutocompletePlate] = useState<any[]>([]);
  const [autocompleteVIN, setAutocompleteVIN] = useState<any[]>([]);
  const [optionValue, setOptionValue] = useState("plate");

  const searchOptions = [
    { value: "plate", label: "Plate" },
    { value: "vin", label: "VIN" },
  ];
  const placeholderTextOverWrite: { [key: string]: string } = {
    plate: "Enter Vehicle Plate Number",
    vin: "Enter Vehicle VIN Number",
  };

  const [, setPlaceholderText2] = useState(placeholderTextOverWrite["plate"]);
  const getAgencies = async () => {
    try {
      const response = await getAgencyListAPI();

      const sortedOptions = response
        .map((item: any) => ({
          label: item.name,
          name: "agencyId",
          value: item._id,
        }))
        .sort((a: any, b: any) => a.label.localeCompare(b.label));

      setAutoCompleteOptions(sortedOptions);
    } catch (error) {
      console.log("Couldn't get agencies", error);
    }
  };
  const getStatuses = (options: any) => {
    setAutoCompleteOptions(
      options.map((item: any) => ({
        label: item.label,
        name: "status",
        value: item._id,
      }))
    );
  };

  const getVinPlateInfo = async () => {
    try {
      const data = await getVehicleInformationAPI();
      setAutocompleteVIN(filterAndMapData(data, "vin", false));
      setAutocompletePlate(filterAndMapData(data, "licensePlate", true));
    } catch (err) {
      console.log("Get Vehicle Info Err: ", err);
    }
  };

  const filterAndMapData = (data: any[], key: string, state: boolean) => {
    return data
      .filter((item) => item[key])
      .map((item) => ({
        _id: item._id || "",
        label: !state
          ? item[key]
          : item[key] + " - " + item.licensePlateState || "",
        vin: item.vin || "",
      }));
  };

  useEffect(() => {
    const fetchData = async () => {
      switch (label.toLowerCase()) {
        case "agency":
          await getAgencies();
          break;
        case "vin":
          getVinPlateInfo();
          break;
        case "status":
          getStatuses(options);
          break;
        default:
          break;
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, options]);

  useEffect(() => {
    if (cleanField) {
      setAutoCompleteValue("");
      setCleanField(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanField]);

  useEffect(() => {
    // Adding logic for lookup modal
    if (vinByLookup) {
      setAutoCompleteValue(filterValues.vin.value);
      setVinByLookup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vinByLookup, filterValues]);

  // Update autocomplete value
  const onAutoCompleteChange = (event: any) => {
    setAutoCompleteValue(event.target.value);
  };

  // Clear autocomplete value
  const onAutoCompleteClear = () => {
    setAutoCompleteValue("");
  };

  // Handle autocomplete selection
  const onAutoCompleteSelect = (option: any) => {
    setAutoCompleteValue(option.label);
    handleChange(
      label.toLowerCase() !== "vin"
        ? { name: option.name, value: option.value }
        : {
            target: { name: "vin", value: option.vin },
          }
    );
  };

  useEffect(() => {
    setAutoCompleteOptions(autocompletePlate);
    setPlaceholderText2(placeholderTextOverWrite["plate"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompletePlate]);

  const handleSelectChange = (e: any) => {
    const { value } = e.target;
    setOptionValue(value);
    setAutoCompleteValue("");
    const options = value === "vin" ? autocompleteVIN : autocompletePlate;
    const placeholder = placeholderTextOverWrite[value];
    setAutoCompleteOptions(options);
    setPlaceholderText2(placeholder);
  };

  return (
    <div className="custom-autocomplete">
      <div className="position-relative">
        {label.toLowerCase() === "vin" ? (
          <div className="position-relative">
            <select
              className="autocomplete-dropdown"
              onChange={handleSelectChange}
              value={optionValue}>
              {searchOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <span className="autocomplete-label">{label}</span>
        )}
        <Autocomplete
          label={"Default Autocomplete"}
          options={autoCompleteOptions}
          value={autoCompleteValue}
          placeholder={
            label.toLocaleLowerCase() === "vin"
              ? placeholderTextOverWrite[optionValue]
              : placeholderText
          }
          onChange={onAutoCompleteChange}
          onClear={onAutoCompleteClear}
          onSelect={onAutoCompleteSelect}
          className="reports-autocomplete"
          data-testid="Test-autocomplete"
        />
        {label.toLowerCase() === "vin" && (
          <button className="vehicle-search" onClick={() => handleSearch()}>
            <FontAwesomeIcon className="icon" icon={faMagnifyingGlass} />
          </button>
        )}
      </div>
    </div>
  );
};
