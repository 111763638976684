import { useEffect, useMemo, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { Marker, MapRef } from "react-map-gl";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { MapView } from "@aws-amplify/ui-react-geo";

import {
  ConnectOneToggle,
  DrawControls,
  GeofenceModal,
  ConnectOneSwitchButtons,
  ConnectOneLookupModal,
  ConnectOneLoader,
  ConnectOneGeneratePolygonModal,
  ConnectOneFlyout,
  ConnectOneMeasurementModal,
  Trips,
  ConnectOneVehicleTrace,
  Clusters,
} from "../../components";
import { MapSearchBar } from "./MapSearchBar";
import { useGeofence, useIsMobile, useMap } from "../../hooks";

import "./Map.scss";
import "@aws-amplify/ui-react-geo/styles.css";
import "maplibre-gl-js-amplify/dist/public/amplify-ctrl-geofence.css";

export const MapPage = ({ user }: { user?: any }) => {
  const map = useRef<MapRef>(null);
  const isMobile = useIsMobile();

  // Get user attributes
  const userIsReadOnly = user.attributes["custom:userType"] === "readonly";
  const userIsAdmin = user.attributes["custom:userType"] === "admin";

  // Read any query params from the URL
  const [searchParams] = useSearchParams();

  // Get the data needed to load trips and traces from the traces report
  const traceVin = searchParams.get("vin") || null;
  const traceDate = searchParams.get("traceDate") || null;
  const traceId = searchParams.get("traceId") || null;

  // Get the locationId from the query params from the location admin page
  const locationId = searchParams.get("locationId") || null;

  // Redux state
  const { initialLoad, geofencesData, rawGeofenceData, isLoading } =
    useSelector((state: any) => state.geofences);
  const { isLoading: agenciesLoading } = useSelector(
    (state: any) => state.agencies
  );
  const { locationHierarchyData, isLoading: locationsLoading } = useSelector(
    (state: any) => state.locationHierarchy
  );

  // Check if the mapbox-draw config is ready before loading the map
  const configReady = !agenciesLoading && !locationsLoading;

  // Use map hook that gives all the map functionality
  const {
    mapStyle,
    tripsLoaded,
    isDataLoading,
    filterValues,
    showControls,
    showFilters,
    showLookupModal,
    showRulerModal,
    dynamicFillColorsByType,
    dynamicFillColorsByStatus,
    measurementData,
    clearRulerLine,
    vehicleTraces,
    vehicleTrips,
    closestReportedTrace,
    displayTripsModal,
    showTraceBalloons,
    tripPoints,
    queriedTrace,
    queriedTrip,
    mapZoom,
    maxZoom,
    vinByLookup,
    setVinByLookup,
    displayRefreshButton,
    setShowLookupModal,
    getVehicleTraceData,
    useInterval,
    handleTripSearch,
    handleFilterChanges,
    handleMapStyleSwitch,
    handleOpenMobileMenu,
    handleMapMeasure,
    handleMapMeasureEnd,
    closeTripsModal,
    selectVehicleTrip,
    searchClosestReportedTrace,
    setFilterValues,
    setQueriedTrip,
    setQueriedTrace,
    handleClusterClick,
    setMapZoom,
    setFilterChanged,
  } = useMap({
    map,
    locationData: locationHierarchyData,
    queryParamsLocation: locationId,
  });

  // Use geofence hook that gives all the geofence functionality
  const {
    mapView,
    modalData,
    displayGeofenceModal,
    isSaving,
    geofenceShapeData,
    realTimeDataToggle,
    geofenceToggles,
    generatePolygonData,
    isEditingGeofence,
    resetView,
    queryParamsGeofence,
    setResetView,
    setRealTimeDataToggle,
    setGeofenceToggles,
    handleGeofenceModalBack,
    handleRemoveScheduleOrRate,
    handleGeofenceUpdate,
    handleGeofenceDelete,
    handleGeofenceSelection,
    handleGeofenceModalClose,
    handleGeofenceUndo,
    handleGeofenceSave,
    handleGeofencePropertyChange,
    handleScheduleOrRateChange,
    handleInsertScheduleOrRate,
    handleDisplayGeneratePolygonModal,
    handleChangeGeneratePolygon,
    handleGeneratePolygon,
    handleCloseGeneratePolygonModal,
    handleScheduleSave,
    handleUndoScheduleChanges,
    handleLockGeofence,
    handleGeofenceClone,
    handleGetAllEntryPoints,
    handleRemoveExclusiveDate,
  } = useGeofence({
    rawGeofenceData,
    queryParamsLocation: locationId,
  });

  const [viewQueriedTrip, setViewQueriedTrip] = useState(false);

  // Use effects
  // If the user is coming from the vehicle traces report set the vin and date and search trips
  useEffect(() => {
    if (initialLoad && traceVin && traceDate && traceId) {
      setFilterValues({
        vin: { ...filterValues["vin"], value: traceVin },
        date: { ...filterValues["date"], value: traceDate },
      });
      handleTripSearch({ selectedVin: traceVin, selectedDate: traceDate });
      setViewQueriedTrip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad]);

  // If trips are loaded and a traceId was given via query params, find the trace
  useEffect(() => {
    // If trips loaded and traceId, find the trace
    if (viewQueriedTrip && traceId) {
      let trace = null as any;
      const trip = vehicleTrips.find((trip: any) => {
        trace = trip.traces.find((t: any) => t._id === traceId);
        return trace;
      });

      if (trip && trace) {
        setQueriedTrip(trip.id);
        setQueriedTrace(traceId);
        selectVehicleTrip(trip, trace);
        setViewQueriedTrip(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tripsLoaded,
    traceId,
    vehicleTrips,
    configReady,
    map?.current,
    viewQueriedTrip,
  ]);

  // Handle only showing on modal at a time
  useEffect(() => {
    // If user opens trips modal, close geofence modal
    if (displayTripsModal) {
      handleGeofenceModalClose({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayTripsModal]);

  useEffect(() => {
    // If user opens geofence modal, close trips modal
    if (displayGeofenceModal) {
      closeTripsModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayGeofenceModal]);

  // Set interval to poll vehicle trace data every 30 seconds or 3 seconds if real-time data is enabled
  useInterval(
    getVehicleTraceData,
    realTimeDataToggle.value ? 3 * 1000 : 30 * 1000
  );

  // Reduces the times the Vehicle Traces are re-rendered when loading live trips
  const VehicleTracesMemo = useMemo(() => {
    if (!vehicleTraces || vehicleTraces.length === 0) {
      return null;
    }

    // If there is only one trace, return a single marker with no balloon
    if (vehicleTraces.length === 1) {
      return (
        <ConnectOneVehicleTrace
          key={vehicleTraces[0]._id}
          tracePoint={vehicleTraces[0]}
          startPoint={false}
          endPoint={false}
          map={map}
          querySelectedTrace={queriedTrace}
        />
      );
    }

    // Move the first trace to the end to help rendering the balloons
    const renderedTraces = [...vehicleTraces].slice(1);
    renderedTraces.push(vehicleTraces[0]);

    return (
      <>
        {vehicleTraces.map((trace) => (
          <ConnectOneVehicleTrace
            key={trace._id}
            tracePoint={trace}
            startPoint={
              showTraceBalloons.startBalloon
                ? tripPoints.startPoint._id === trace._id
                : false
            }
            endPoint={
              showTraceBalloons.endBalloon
                ? tripPoints.endPoint._id === trace._id
                : false
            }
            map={map}
            querySelectedTrace={queriedTrace}
          />
        ))}
      </>
    );
  }, [
    vehicleTraces,
    showTraceBalloons.startBalloon,
    showTraceBalloons.endBalloon,
    tripPoints?.startPoint?._id,
    tripPoints?.endPoint?._id,
    queriedTrace,
  ]);

  // Reduces the times the Geofence Clusters are re-rendered when the map zoom
  const GeofenceClustersMemo = useMemo(() => {
    // Satellite view does not support clusters
    if (
      geofencesData?.features &&
      mapStyle.style !== "GNSS_Tolling_UI-Imagery"
    ) {
      // Clusters only work with point data, so convert the geofences to points for clustering
      const featuresAsPoints = geofencesData?.features.map((feature: any) => {
        return {
          ...feature,
          geometry: {
            type: "Point",
            coordinates: feature.properties.center,
          },
        };
      });

      return (
        <Clusters
          clusterFeatures={featuresAsPoints}
          mapZoom={mapZoom}
          maxZoom={maxZoom}
        />
      );
    }
  }, [geofencesData?.features, mapStyle.style, mapZoom, maxZoom]);

  return (
    <div
      className={`${
        isMobile ? "mobile-map-container" : "desktop-map-container"
      } map-container ${isEditingGeofence ? "editing-map-view" : null}`}
      data-testid="map-page"
    >
      {initialLoad && <ConnectOneLoader />}

      {/* VIN Lookup Modal */}
      {showLookupModal && (
        <ConnectOneLookupModal
          label="VIN"
          lookupType="vin"
          handleClose={() => setShowLookupModal(false)}
          handleSetValue={(data: any) => {
            handleFilterChanges(data);
            setShowLookupModal(false);
          }}
          setVinByLookup={setVinByLookup}
        />
      )}

      {/* Ruler Modal */}
      {showRulerModal && (
        <ConnectOneMeasurementModal
          data={measurementData}
          handleClose={handleMapMeasureEnd}
        />
      )}

      {/* Generate Polygon Modal */}
      {generatePolygonData.displayGeneratePolygonModal && (
        <ConnectOneGeneratePolygonModal
          data={generatePolygonData}
          handleChange={handleChangeGeneratePolygon}
          handleSubmit={handleGeneratePolygon}
          handleClose={handleCloseGeneratePolygonModal}
        />
      )}

      {/* Wait for the map configuration to be loaded before render */}
      {configReady && (
        <MapView
          ref={map}
          attributionControl={false}
          initialViewState={mapView}
          dragRotate={false}
          mapStyle={mapStyle.style}
          interactiveLayerIds={["geofenceClusters"]}
          onClick={handleClusterClick}
        >
          {/* Map search bar */}
          {showFilters && (
            <MapSearchBar
              filterValues={filterValues}
              rawGeofenceData={rawGeofenceData.features}
              handleSubmit={handleTripSearch}
              setShowLookupModal={() => setShowLookupModal(true)}
              handleFilterChanges={handleFilterChanges}
              handleOpenMobileMenu={handleOpenMobileMenu}
              isDataLoading={isDataLoading}
              showFilters={showFilters}
              queriedByTrace={!!queriedTrace}
              vinLookup={vinByLookup}
              setVinLookup={setVinByLookup}
              geofenceModalOpen={isEditingGeofence}
              customLabel={tripsLoaded || queriedTrace ? "View" : null}
              setFilterChanged={setFilterChanged}
            />
          )}

          {/* Geofence Drawing Controls */}
          <DrawControls
            onUpdate={handleGeofenceUpdate}
            onSelect={handleGeofenceSelection}
            onGenerate={handleDisplayGeneratePolygonModal}
            onMeasure={handleMapMeasure}
            onMeasureEnd={handleMapMeasureEnd}
            onGeofenceDelete={handleGeofenceDelete}
            clearRulerLine={clearRulerLine}
            geofences={geofencesData}
            userIsReadOnly={userIsReadOnly}
            resetView={resetView}
            setResetView={setResetView}
            dynamicFillColorsByType={dynamicFillColorsByType}
            dynamicFillColorsByStatus={dynamicFillColorsByStatus}
            setMapZoom={setMapZoom}
          />

          {/* Trips Modal */}
          {!displayGeofenceModal && (
            <ConnectOneFlyout displayFlyout={displayTripsModal}>
              <Trips
                trips={vehicleTrips}
                querySelectedTrip={queriedTrip}
                onTripSelect={selectVehicleTrip}
                closeTripsModal={closeTripsModal}
                closestReportedTrace={closestReportedTrace}
                searchClosestReportedTrace={(date: string) =>
                  searchClosestReportedTrace(date)
                }
                refreshTrips={displayRefreshButton() ? handleTripSearch : null}
              />
            </ConnectOneFlyout>
          )}

          {/* Vehicle Traces */}
          {VehicleTracesMemo}

          {/* Geofence Clusters */}
          {GeofenceClustersMemo}

          {/* View on Map from Locations Admin Balloon */}
          {!!queryParamsGeofence && (
            <Marker
              latitude={mapView.latitude}
              longitude={mapView.longitude}
              color={"red"}
            />
          )}

          {/* Geofence Modal */}
          {!displayTripsModal && (
            <ConnectOneFlyout displayFlyout={displayGeofenceModal}>
              <GeofenceModal
                geofence={modalData}
                isLoading={isLoading}
                userIsReadOnly={userIsReadOnly}
                userIsAdmin={userIsAdmin}
                isSavingGeofence={isSaving}
                onClose={handleGeofenceModalClose}
                onEditViewClose={handleGeofenceModalBack}
                changesMade={!isEmpty(geofenceShapeData)}
                handlePropertyChange={handleGeofencePropertyChange}
                handleScheduleOrRateChange={handleScheduleOrRateChange}
                handleInsertScheduleOrRate={handleInsertScheduleOrRate}
                handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
                handleScheduleSave={handleScheduleSave}
                handleUndoScheduleChanges={handleUndoScheduleChanges}
                handleUndo={handleGeofenceUndo}
                handleDelete={handleGeofenceDelete}
                handleSubmit={handleGeofenceSave}
                handleLockGeofence={handleLockGeofence}
                handleGeofenceClone={handleGeofenceClone}
                handleGetAllEntryPoints={handleGetAllEntryPoints}
                handleRemoveExclusiveDate={handleRemoveExclusiveDate}
              />
            </ConnectOneFlyout>
          )}
        </MapView>
      )}

      {/* Map Options Menu */}
      {(isMobile || !showControls) && (
        <div
          className={
            isMobile
              ? "mobile-map-controls-container"
              : "map-controls-container"
          }
          onClick={() => handleOpenMobileMenu("controls")}
        >
          <p>Map Options</p>
        </div>
      )}

      {showControls && (
        <div className="toggle-container" data-testid="toggle-container">
          <div className={isMobile ? "mobile-map-controls" : null}>
            {!isMobile && (
              <div
                className="x-container"
                onClick={() => handleOpenMobileMenu("controls")}
                data-testid="search-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </div>
            )}
            <div className="toggles">
              <p>Vehicle Traces:</p>
              <ConnectOneToggle
                checked={realTimeDataToggle.value}
                disabled={false}
                label={realTimeDataToggle.label}
                handleChange={(e: any) => {
                  setRealTimeDataToggle({
                    ...realTimeDataToggle,
                    value: e,
                  });
                }}
              />

              <p>Location Types:</p>
              {Object.entries(geofenceToggles).map(
                ([key, value]: [key: string, value: any]) => {
                  return (
                    <span key={value.label}>
                      <ConnectOneToggle
                        checked={value.value}
                        disabled={false}
                        label={value.label}
                        handleChange={(e: any) => {
                          setGeofenceToggles({
                            ...geofenceToggles,
                            [key]: {
                              ...geofenceToggles[key],
                              value: e,
                            },
                          });
                        }}
                      />
                    </span>
                  );
                }
              )}
            </div>

            <ConnectOneSwitchButtons
              header="Map Style"
              values={["Basic", "Hybrid", "Satellite"]}
              active={mapStyle.name}
              handleClick={(e: any) => {
                handleMapStyleSwitch(e.target.value);
              }}
            />
          </div>
          {isMobile && (
            <p
              onClick={() => handleOpenMobileMenu("controls")}
              className="mobile-close-button"
            >
              CLOSE
            </p>
          )}
        </div>
      )}
    </div>
  );
};
