// ConnectOneFilterBar component
// The Filter Bar takes in an object of filterValues. Each key in the object represents a filter input. The value of each key is an object with the following properties:
//     type: The type of input (e.g., text, dropdown, search, autocomplete).
//     label: The label for the input.
//     value: The value of the input.
//     options: An array of options for dropdown or autocomplete inputs.
//     placeholderText: The placeholder text for the input.

//! If the key of the filterValue does not already exist in the filterValues object, the new key will be added to the object with the value of the input. Causing a new filter input to be rendered on the page.

import { useState } from "react";
import {
  ConnectOneInput,
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneSearchInput,
  ConnectOneAutocomplete,
} from "../..";
import { useIsMobile } from "../../../hooks";

import "./FilterBar.scss";

export const ConnectOneFilterBar = ({
  handleChange,
  filterValues,
  handleSubmit,
  dataIsLoading = false,
  submitButtonDisabled = true,
  showFilters = true,
  handleOpenMobileMenu = () => {},
  handleSearch = () => {},
  customLabel = null,
  buttonToolTipMessage = null,
  vinByLookup,
  setVinByLookup,
}: {
  handleChange: any;
  filterValues: any;
  handleSubmit: any;
  dataIsLoading?: boolean;
  submitButtonDisabled?: boolean;
  showFilters?: boolean;
  handleOpenMobileMenu?: any;
  handleSearch?: any;
  customLabel?: string | null;
  buttonToolTipMessage?: string | null;
  vinByLookup?: boolean;
  setVinByLookup?: any;
}) => {
  const isMobile = useIsMobile();

  const [cleanField, setCleanField] = useState(false);

  return (
    <>
      <div className="report-filters">
        {isMobile && (
          <div
            className="minimized-mobile-filter-container filter-container"
            data-testid="minimized-mobile-filter-bar"
            onClick={() => handleOpenMobileMenu("filterBar")}>
            <p data-testid="open-filter-button">Search</p>
          </div>
        )}

        {showFilters && (
          <div
            className={`${
              isMobile ? "mobile-filter-container" : null
            } filter-container`}
            data-testid={`${isMobile ? "mobile-filter-bar" : "filter-bar"}`}>
            <div className="filter-with-button-container">
              <div className="filter-inputs-container">
                {Object.entries(filterValues).map(
                  ([key, value]: [key: string, value: any], index) => {
                    // Handle Autocomplete filters
                    if (value.type === "autocomplete") {
                      return (
                        <span key={`autocomplete-input-${index}`}>
                          <ConnectOneAutocomplete
                            label={value.label}
                            placeholderText={value.placeholderText}
                            handleChange={handleChange}
                            options={value.options}
                            handleSearch={handleSearch}
                            filterValues={filterValues}
                            vinByLookup={vinByLookup}
                            setVinByLookup={setVinByLookup}
                            cleanField={cleanField}
                            setCleanField={setCleanField}
                          />
                        </span>
                      );
                    }
                    // Handle Dropdown filters
                    if (value.type === "dropdown") {
                      return (
                        <span key={`dropdown-input-${index}`}>
                          <ConnectOneDropdown
                            handleChange={handleChange}
                            label={value.label}
                            name={key}
                            placeholder={value.label}
                            value={value.value}
                            options={value.options}
                          />
                        </span>
                      );
                    }
                    // Handle Search filters
                    if (value.type === "search") {
                      return (
                        <span key={`seach-input-${index}`}>
                          <ConnectOneSearchInput
                            name={key}
                            label={value.label}
                            value={value.value}
                            handleChange={handleChange}
                            handleSearch={handleSearch}
                            labelHidden={value.labelHidden}
                          />
                        </span>
                      );
                    }
                    // Handle Text filters
                    return (
                      <span key={`text-input-${index}`}>
                        <ConnectOneInput
                          handleChange={handleChange}
                          label={value.label}
                          type={value.type}
                          name={key}
                          placeholder={value.label}
                          value={value.value}
                          min={value.min}
                          max={value.max}
                        />
                      </span>
                    );
                  }
                )}
              </div>

              <div className="submit-button">
                <ConnectOneButton
                  label={customLabel ? customLabel : "Search"}
                  disabled={submitButtonDisabled}
                  handleClick={() => {
                    if (isMobile) handleOpenMobileMenu("filterBar");
                    handleSubmit();
                    setCleanField(true);
                  }}
                  isLoading={dataIsLoading}
                  toolTipMessage={buttonToolTipMessage}
                />
              </div>
            </div>
            {isMobile && (
              <p
                onClick={() => handleOpenMobileMenu("filterBar")}
                className="mobile-close-button"
                data-testid="mobile-close-button">
                CLOSE
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};
