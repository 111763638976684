import { useEffect, useState } from "react";
import { format, addDays } from "date-fns";

import { formatDateToAPI, getFriendlyTimezoneName } from "../../../utils";
import { getDataProvidersAPI, getVehicleMessagesAPI } from "../../../axios";
import { useIsMobile, useReport } from "../../../hooks";
import { ConnectOneTable, ConnectOneTableControls } from "../../../components";

export const VehicleCommunicationsReport = () => {
  const isMobile = useIsMobile();
  const {
    reportData,
    currentPageIndex,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    exportFileName,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    handleSorting,
  } = useReport({ isMobile, reportType: "vehicleMessages" });

  const [filterValues, setFilterValues] = useState({
    vin: {
      type: "autocomplete",
      label: "Vin",
      value: "",
      placeholderText: "Select a VIN",
    },
    provider: {
      type: "dropdown",
      label: "Provider",
      value: "",
      options: [] as any,
    },
    dateFrom: {
      type: "datetime-local",
      label: "Date From",
      value: `${format(addDays(new Date(), -7), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Date To",
      value: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
    },
  });

  const submitButtonEnabled = Object.values(filterValues).some(
    (filterValue: any) => !!filterValue.value
  );

  const getDataProviders = async () => {
    try {
      const dataProvidersOptions = await getDataProvidersAPI();
      setFilterValues((prevFilters: any) => {
        return {
          ...prevFilters,
          provider: {
            ...prevFilters.provider,
            options: [
              { label: "Select a provider", value: "", disabled: true },
              ...dataProvidersOptions,
            ],
          },
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDataProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTableData = async () => {
    setIsLoading(true);
    setCurrentPageIndex(1);
    try {
      const res: any = await getVehicleMessagesAPI({
        vin: filterValues.vin.value,
        dateFrom: formatDateToAPI(filterValues.dateFrom.value),
        dateTo: formatDateToAPI(filterValues.dateTo.value),
        provider: filterValues.provider.value,
      });

      const shapedReportData = res.map((message: any) => {
        // Transform the date to a user-friendly format matching other data
        const requestDateTime = message?.requestTime
          ? formatDateToAPI(message?.requestTime)
          : null;

        return {
          ...message,
          requestDateTime,
          timeZoneName: getFriendlyTimezoneName(
            message.timeZone,
            message.localTimeStamp
          ),
        };
      });

      setReportData(shapedReportData);
      setIsLoading(false);
    } catch (err) {
      setReportData([]);
      console.log(err);
    }
  };

  return (
    <>
      <div className="vehicle-messages-container">
        <ConnectOneTableControls
          fullData={reportData}
          dataIsLoading={isLoading}
          totalPages={totalPages}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          handleFilterSubmit={getTableData}
          submitButtonDisabled={!submitButtonEnabled}
          fileName={exportFileName("VEHICLE_MESSAGES", filterValues)}
          exportData={exportData}
          reportTitle="Vehicle Message Report"
        />
      </div>
      <ConnectOneTable
        data={dataToDisplay}
        headers={headers}
        caption="Vehicle Messages"
        sortGridData={handleSorting}
        sortAscending={sortAscending}
        sortedValue={sortedValue}
      />
    </>
  );
};
