import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ArrowIcon } from "../../../assets";

import "./Table.scss";

export const ConnectOneTable = ({
  headers,
  data,
  caption = "",
  isMatching = false,
  sortGridData = undefined,
  sortAscending,
  sortedValue,
}: {
  headers: any[];
  data: any[];
  caption?: string;
  isMatching?: boolean;
  sortGridData?: any;
  sortAscending?: boolean;
  sortedValue?: string;
}) => {
  return (
    <div data-testid="table-container" className="table-container">
      {!!data?.length && (
        <div
          className={`${!!data.length ? "table" : null} ${
            isMatching ? "matching-tables" : null
          }`}>
          <div className="report-title">{caption}</div>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => {
                  return (
                    <TableCell
                      as="th"
                      onClick={
                        sortGridData ? () => sortGridData(header.value) : null
                      }
                      key={header.name}
                      data-testid={`${header.value}-header`}>
                      <div className="cell-header tooltip">
                        <p>{header.name}</p>
                        {header.helpText && (
                          <span className="tooltipText">{header.helpText}</span>
                        )}

                        {sortedValue === header.value && (
                          <span
                            className={
                              sortAscending
                                ? "arrow-icon-ascending"
                                : "arrow-icon-descending"
                            }>
                            <ArrowIcon />
                          </span>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                // In some cases a row should be a different color, check value in row object
                const rowColor = row.rowColor || null;

                return (
                  <TableRow
                    key={`row-${index}`}
                    style={{
                      backgroundColor: rowColor,
                    }}>
                    {headers.map((header: any) => {
                      let cellValue: any = "";

                      // Handling any action buttons in a table
                      if (header.value === "actionButton") {
                        return (
                          <TableCell
                            key={`row-${index}-action-button`}
                            data-testid={`action-button-cell-data`}
                            title={row["actionButton"]?.tooltip}>
                            <FontAwesomeIcon
                              onClick={row["actionButton"]?.actionFunction}
                              icon={row["actionButton"]?.icon}
                              size="2xl"
                              style={{ cursor: "pointer", maxWidth: "25px" }}
                              data-testid="action-button"
                            />
                          </TableCell>
                        );
                      }

                      if (
                        header.parentValue &&
                        row.hasOwnProperty(header.parentValue) &&
                        row[header.parentValue].hasOwnProperty(header.value)
                      ) {
                        cellValue = `${row[header.parentValue][header.value]}`;
                      } else if (
                        row.hasOwnProperty(header.value) &&
                        row[header.value] !== null
                      ) {
                        cellValue = `${row[header.value]}`;
                      }

                      if (typeof cellValue === "object") {
                        cellValue = cellValue.label;
                      }

                      if (typeof cellValue === "boolean") {
                        cellValue = cellValue ? "True" : "False";
                      }

                      // Handle empty cells
                      if (cellValue === "") {
                        cellValue = "N/A";
                      }

                      return (
                        <TableCell
                          key={`row-${index}-${header.name}`}
                          data-testid={`${cellValue}-cell-data`}>
                          {cellValue}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
