// Storing empty versions of editable state for when adding a whole object to state

export const emptyCharge: any = {
  isRegistered: true,
  avi: null,
  plateRead: null,
  equityRate: null,
  cashRate: null,
  hovRate: null,
  negotiatedRate: null,
};

export const emptySchedule: any = {
  vehicleClass: "Class1",
  dayOfWeekStart: 1,
  dayOfWeekEnd: 7,
  timeOfDayStart: "12:00:00 AM",
  timeOfDayEnd: "11:59:59 PM",
  isAccountRate: true,
  startEffectiveDate: "2001-01-01T00:00:00.000Z",
  endEffectiveDate: "9999-12-31T23:59:59.999Z",
  charges: [emptyCharge],
  isNewlyCreated: true,
};
