// Custom TripCard is a presentational component that will render a single trip and have an onClick event to view traces with custom range controls
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConnectOneInput } from "../inputs";

import "./Trips.scss";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const CustomRangeTripCard = ({
  trip,
  customRange,
  setCustomRange,
  selectedTrip = undefined,
  onTripSelect = () => {},
}: {
  trip: any;
  customRange: { startTime: string; endTime: string };
  setCustomRange: any;
  selectedTrip?: string;
  onTripSelect?: (trip: any) => void;
}) => {
  return (
    <div
      className={`custom-range-trip-card ${
        selectedTrip === trip.id ? "selected-trip-card" : ""
      }`}
      data-testid={`trip-card-${trip.id}`}>
      <h2>Custom Range</h2>
      <div className="custom-range-time-inputs-container">
        <ConnectOneInput
          name="startTime"
          label="Start Time"
          type="time"
          value={customRange.startTime}
          handleChange={(e: any) =>
            setCustomRange({ ...customRange, startTime: e.target.value })
          }
        />
        <ConnectOneInput
          name="endTime"
          label="End Time"
          type="time"
          value={customRange.endTime}
          handleChange={(e: any) =>
            setCustomRange({ ...customRange, endTime: e.target.value })
          }
        />
        {/* <ConnectOneButton
          label="Filter"
          handleClick={() => onTripSelect(trip)}
          data-testid={`view-traces-button-${trip.id}`}
        /> */}
        <button
          onClick={() => onTripSelect(trip)}
          data-testid={`view-traces-button-${trip.id}`}
          className="customRangeTrip-btn">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};
