import { useCallback } from "react";
import { LocationSearch } from "@aws-amplify/ui-react-geo";

import { GlobeIcon } from "../../../assets";

export const ConnectOneLocationSearch = ({
  geofences,
  isMobile,
  localGeocoderOnly,
  setLocalGeocoderOnly,
}: {
  geofences: any;
  isMobile: boolean;
  localGeocoderOnly?: boolean;
  setLocalGeocoderOnly?: any;
}) => {
  const handleLocalGeocoder = useCallback(
    (query: string) => {
      return [...geofences].filter((geofence: any) => {
        return (
          geofence.place_name.toLowerCase().includes(query.toLowerCase()) ||
          `${geofence.plazaId}`.toLowerCase().includes(query.toLowerCase())
        );
      });
    },
    [geofences]
  );

  return (
    <div data-testid="location-search-input" className="location-search-input">
      <span
        onClick={() => setLocalGeocoderOnly(!localGeocoderOnly)}
        title="Toggle between Connect.One Geofence Locations and Global Map Locations">
        <GlobeIcon strokeColor={localGeocoderOnly ? "#3d3935" : "#3956ff"} />
      </span>

      <LocationSearch
        key={`Location-Search-${geofences?.length}-${new Date().valueOf()}`}
        position={isMobile ? "bottom-left" : "top-left"}
        countries="USA"
        zoom={17}
        popup={false}
        marker={{ color: "red" }}
        placeholder={
          localGeocoderOnly ? "Enter Geofence Name" : "Search by Map Location"
        }
        showResultMarkers={false}
        localGeocoder={handleLocalGeocoder}
        localGeocoderOnly={localGeocoderOnly}
      />
    </div>
  );
};
