import { useEffect, useState } from "react";
import { Autocomplete } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { useIsMobile } from "../../hooks";
import { getVehicleInformationAPI } from "../../axios";
import { ConnectOneButton, ConnectOneLocationSearch } from "../../components";

import "./Map.scss";

export const MapSearchBar = ({
  filterValues,
  rawGeofenceData,
  handleSubmit,
  setShowLookupModal,
  handleFilterChanges,
  handleOpenMobileMenu = () => {},
  isDataLoading = false,
  showFilters = true,
  queriedByTrace = false,
  vinLookup,
  setVinLookup,
  geofenceModalOpen,
  customLabel = null,
  setFilterChanged,
}: {
  filterValues: any;
  rawGeofenceData: any;
  handleSubmit: any;
  setShowLookupModal: any;
  handleFilterChanges: any;
  handleOpenMobileMenu?: any;
  isDataLoading?: boolean;
  showFilters?: boolean;
  queriedByTrace?: boolean;
  vinLookup?: boolean;
  setVinLookup?: any;
  geofenceModalOpen?: boolean;
  customLabel?: string | null;
  setFilterChanged?: any;
}) => {
  const isMobile = useIsMobile();

  const searchOptions = [
    { value: "plate", label: "Plate" },
    { value: "vin", label: "VIN" },
    { value: "geofence", label: "Geofence" },
    { value: "map", label: "Map" },
    { value: "nickname", label: "Nickname" },
  ];

  const placeholderTextMap: { [key: string]: string } = {
    plate: "Enter Vehicle Plate Number",
    vin: "Enter Vehicle VIN Number",
    geofence: "Enter Geofence Name",
    map: "Search by Map Location",
    nickname: "Enter Vehicle Nickname",
  };

  // State for inputs
  const [optionValue, setOptionValue] = useState("plate");
  const [placeholderText, setPlaceholderText] = useState(
    placeholderTextMap["plate"]
  );
  const [hiddenInput, setHiddenInput] = useState(false);
  const [additionalFilter, setAdditionalFilter] = useState(false);

  // Autocomplete state
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(
    !queriedByTrace
  );
  const [autocompletePlate, setAutocompletePlate] = useState<any[]>([]);
  const [autocompleteVIN, setAutocompleteVIN] = useState<any[]>([]);
  const [autocompleteNickname, setAutocompleteNickname] = useState<any[]>([]);

  // Location search state
  const [localGeocoderOnly, setLocalGeocoderOnly] = useState(false);

  useEffect(() => {
    // Check if option value is vehicle related
    // Check for empty filter values
    // If the filter value is empty, disable the submit button
    if (
      ["plate", "vin", "nickname"].includes(optionValue) &&
      !filterValues["vin"].value
    ) {
      setSubmitButtonDisabled(true);
    } else {
      setSubmitButtonDisabled(false);
    }
  }, [filterValues, optionValue, queriedByTrace]);

  useEffect(() => {
    // Fetch vehicle information
    const fetchVehicleInfo = async () => {
      try {
        const data = await getVehicleInformationAPI();
        setAutocompleteVIN(filterAndMapData(data, "vin", false));
        setAutocompletePlate(filterAndMapData(data, "licensePlate", true));
        setAutocompleteNickname(filterAndMapData(data, "nickName", false));
      } catch (err) {
        console.log("Get Vehicle Info Err: ", err);
      }
    };

    fetchVehicleInfo();
  }, []);

  useEffect(() => {
    // If the user is coming from admin traces page, set the filters
    if (queriedByTrace) {
      setAutoCompleteValue(filterValues["vin"].value);
      setOptionValue("vin");
      setPlaceholderText(placeholderTextMap["vin"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, queriedByTrace]);

  // Filter and map vehicle data
  const filterAndMapData = (data: any[], key: string, state: boolean) => {
    console.log(data);
    return data
      .filter((item) => item[key])
      .map((item) => ({
        _id: item._id || "",
        label: !state
          ? item[key]
          : item[key] + " - " + item.licensePlateState || "",
        vin: item.vin || "",
      }));
  };

  // Handle additional filter change
  useEffect(() => {
    const isAdditionalFilter = ["plate", "vin", "nickname"].includes(
      optionValue
    );
    setAdditionalFilter(isAdditionalFilter);

    setHiddenInput(optionValue === "geofence" || optionValue === "map");
    setLocalGeocoderOnly(optionValue === "geofence");

    const autoCompleteOptionsMap: { [key: string]: any[] } = {
      plate: autocompletePlate,
      vin: autocompleteVIN,
      nickname: autocompleteNickname,
    };

    setAutoCompleteOptions(autoCompleteOptionsMap[optionValue] || []);
    setFilterChanged(true);

    // If user is coming from admin traces page, enable "view" button
    if (!queriedByTrace) {
      setSubmitButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteNickname, autocompletePlate, autocompleteVIN, optionValue]);

  // Handle search option change
  const handleSelectChange = (e: any) => {
    // Update placeholder text and option value based on selected option
    setOptionValue(e.target.value);
    setAutoCompleteValue("");
    setFilterChanged(true);
    setPlaceholderText(placeholderTextMap[e.target.value] || "Search");
  };

  // Update autocomplete value
  const onAutoCompleteChange = (event: any) => {
    setAutoCompleteValue(event.target.value);
    setSubmitButtonDisabled(true);
    setFilterChanged(true);
  };

  // Clear autocomplete value
  const onAutoCompleteClear = () => {
    setAutoCompleteValue("");
  };

  // Handle autocomplete selection
  const onAutoCompleteSelect = (option: any) => {
    handleFilterChanges({
      target: { name: "vin", value: option.vin },
    });
    setAutoCompleteValue(option.label);
  };

  useEffect(() => {
    // Adding logic for lookup modal
    if (vinLookup) {
      setAutoCompleteValue(filterValues.vin.value);
      setOptionValue("vin");
      setVinLookup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vinLookup, filterValues]);

  return (
    <>
      {isMobile && (
        <div
          className="minimized-mobile-filter-container filter-container"
          data-testid="minimized-mobile-filter-bar"
          onClick={() => handleOpenMobileMenu("filterBar")}
        >
          <p data-testid="open-filter-button">Search</p>
        </div>
      )}
      {showFilters && (
        <div className="position-relative">
          <div
            className={`${
              isMobile ? "mobile-filter-container" : ""
            } filter-container`}
            data-testid={`${isMobile ? "mobile-filter-bar" : "filter-bar"}`}
          >
            <div className="map-filter-container">
              <div
                className="map-filter-box"
                style={{ maxWidth: hiddenInput && "78vw" }}
              >
                <select
                  className="map-filter-dropdown"
                  onChange={handleSelectChange}
                  value={optionValue}
                >
                  {searchOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <Autocomplete
                  label="Default Autocomplete"
                  options={autoCompleteOptions}
                  value={autoCompleteValue}
                  placeholder={placeholderText}
                  onChange={onAutoCompleteChange}
                  onClear={onAutoCompleteClear}
                  onSelect={onAutoCompleteSelect}
                  className="map-search-input"
                />
                {hiddenInput && !geofenceModalOpen && (
                  <div className="position-relative">
                    <div className="geofence-map-lookup">
                      <ConnectOneLocationSearch
                        geofences={rawGeofenceData}
                        isMobile={isMobile}
                        localGeocoderOnly={localGeocoderOnly}
                      />
                    </div>
                  </div>
                )}
                <div className="position-relative">
                  {additionalFilter && (
                    <button
                      className="map-search-icon"
                      onClick={() => setShowLookupModal(true)}
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon={faMagnifyingGlass}
                      />
                    </button>
                  )}
                </div>
              </div>
              {additionalFilter && (
                <>
                  <div className="date-filter">
                    <input
                      type="date"
                      value={filterValues["date"].value}
                      onChange={handleFilterChanges}
                      name="date"
                    />
                  </div>
                  <div className="search-button">
                    <ConnectOneButton
                      label={customLabel ? customLabel : "Search"}
                      disabled={submitButtonDisabled}
                      isLoading={isDataLoading}
                      handleClick={() => {
                        if (isMobile) handleOpenMobileMenu("filterBar");
                        handleSubmit({});
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
